import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaPlus, FaEdit, FaTrash, FaSignOutAlt, FaDownload, FaFileImport } from 'react-icons/fa';
import { getAllStories, deleteStory, isAdminLoggedIn, logoutAdmin, exportStoriesToJson, importStoriesFromJson } from '../../utils/localDataUtils';
import axios from 'axios';

const Dashboard = () => {
  const navigate = useNavigate();
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [jsonFile, setJsonFile] = useState(null);
  const [jsonSuccess, setJsonSuccess] = useState(false);
  const [webData, setWebData] = useState([]);
  const fileInputRef = useRef(null);

  // 관리자 로그인 확인
  useEffect(() => {
    if (!isAdminLoggedIn()) {
      navigate('/admin/login');
    }
  }, [navigate]);

  // 스토리 데이터 불러오기
  useEffect(() => {
    const fetchStories = async () => {
      try {
        setLoading(true);
        const storiesData = await getAllStories();
        // 스토리 데이터를 최신순으로 정렬
        const sortedStories = [...storiesData].sort((a, b) => {
          const dateA = new Date(a.createdAt || 0);
          const dateB = new Date(b.createdAt || 0);
          return dateB - dateA; // 최신순으로 정렬
        });
        setStories(sortedStories);
        setLoading(false);
      } catch (error) {
        console.error('스토리 불러오기 오류:', error);
        setError('스토리를 불러오는 중 오류가 발생했습니다.');
        setLoading(false);
      }
    };

    fetchStories();
  }, [deleteSuccess, jsonSuccess]);

  // 로그아웃 처리
  const handleLogout = () => {
    logoutAdmin();
    navigate('/admin/login');
  };

  // 스토리 삭제 확인
  const confirmDelete = (id) => {
    setDeleteConfirm(id);
  };

  // 스토리 삭제 취소
  const cancelDelete = () => {
    setDeleteConfirm(null);
  };

  // 스토리 삭제 처리
  const handleDelete = async (id) => {
    try {
      await deleteStory(id);
      setDeleteConfirm(null);
      setDeleteSuccess(true);
      
      // 3초 후 성공 메시지 숨기기
      setTimeout(() => {
        setDeleteSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('스토리 삭제 오류:', error);
      setError('스토리를 삭제하는 중 오류가 발생했습니다.');
    }
  };

  // JSON 데이터 불러오기
  useEffect(() => {
    const fetchWebData = async () => {
      try {
        setLoading(true);
        // GitHub Pages에서 데이터 불러오기
        const timestamp = new Date().getTime(); // 현재 시간을 기준으로 캐시 무효화
        const response = await axios.get(`./data/stories.json?t=${timestamp}`);
        if (response.data && Array.isArray(response.data)) {
          // 데이터를 최신순으로 정렬
          const sortedStories = [...response.data].sort((a, b) => {
            const dateA = new Date(a.createdAt || 0);
            const dateB = new Date(b.createdAt || 0);
            return dateB - dateA; // 최신순으로 정렬
          });
          setWebData(sortedStories);
          console.log('최신 JSON 데이터 불러오기 성공:', sortedStories.length, '개의 데이터');
        } else {
          console.warn('최신 JSON 데이터 불러오기: 데이터가 비어 있거나 잘못된 형식입니다.');
          setWebData([]);
        }
      } catch (error) {
        console.error('JSON 데이터 불러오기 오류:', error);
        setWebData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchWebData();
  }, []);

  // JSON 파일 선택
  const handleJsonFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setJsonFile(file);
    }
  };

  // JSON 데이터 가져오기
  const handleImportJson = async () => {
    if (!jsonFile) {
      setError('JSON 파일을 선택해주세요.');
      return;
    }

    try {
      setLoading(true);
      await importStoriesFromJson(jsonFile);
      setJsonSuccess(true);
      setError(null);
      setJsonFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      
      // 3초 후 성공 메시지 숨기기
      setTimeout(() => {
        setJsonSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('JSON 데이터 가져오기 오류:', error);
      setError(`JSON 데이터 가져오기 실패: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // JSON 데이터 내보내기
  const handleExportJson = () => {
    try {
      exportStoriesToJson();
      setJsonSuccess(true);
      setError(null);
      
      // 3초 후 성공 메시지 숨기기
      setTimeout(() => {
        setJsonSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('JSON 데이터 내보내기 오류:', error);
      setError(`JSON 데이터 내보내기 실패: ${error.message}`);
    }
  };

  // 로딩 중 표시
  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8">관리자 대시보드</h1>
        <div className="flex justify-center items-center h-64">
          <p className="text-lg">스토리를 불러오는 중...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 p-4">
      <div className="max-w-6xl mx-auto bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">관리자 대시보드</h1>
            
            <div className="flex items-center space-x-4">
              <Link
                to="/admin/story/new"
                className="flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-300"
              >
                <FaPlus className="mr-2" />
                새 스토리 작성
              </Link>
              
              <button
                onClick={handleLogout}
                className="flex items-center px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-300"
              >
                <FaSignOutAlt className="mr-2" />
                로그아웃
              </button>
            </div>
          </div>
          
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg mb-6">
              {error}
            </div>
          )}
          
          {deleteSuccess && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg mb-6">
              스토리가 성공적으로 삭제되었습니다.
            </div>
          )}

          {jsonSuccess && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg mb-6">
              JSON 데이터 가져오기 성공
            </div>
          )}

          {/* JSON 데이터 관리 */}
          <div className="mb-8 p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">JSON 데이터 관리</h2>
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
              <p className="text-yellow-700 font-medium">GitHub Pages 배포 안내</p>
              <ol className="list-decimal pl-5 mt-2 space-y-2 text-yellow-700">
                <li>현재 대시보드에서 변경한 데이터는 <strong>로컬 브라우저</strong>에만 저장됩니다.</li>
                <li>변경사항을 GitHub Pages에 반영하려면 다음 단계를 따라야 합니다:</li>
                <li className="font-semibold">1) "JSON 데이터 내보내기" 버튼을 클릭하여 stories.json 파일을 다운로드합니다.</li>
                <li className="font-semibold">2) 다운로드한 stories.json 파일을 프로젝트의 public/data/stories.json 경로에 저장합니다.</li>
                <li className="font-semibold">3) <code>npm run deploy</code> 명령어를 실행하여 GitHub Pages에 배포합니다.</li>
                <li className="font-semibold">4) 배포 후 웹사이트에서 변경사항이 반영되었는지 확인합니다.</li>
              </ol>
            </div>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              <strong>중요:</strong> stories.json 파일을 public/data 폴더에 저장한 후 <code>npm run deploy</code>를 실행해야 변경사항이 GitHub Pages에 반영됩니다.
            </p>
            
            <div className="flex flex-wrap gap-4">
              {/* JSON 데이터 내보내기 */}
              <button
                type="button"
                onClick={handleExportJson}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition-colors duration-300"
              >
                <FaDownload className="mr-2" />
                JSON 데이터 내보내기
              </button>
              
              {/* JSON 데이터 가져오기 */}
              <div className="flex">
                <label className="bg-purple-500 text-white px-4 py-2 rounded-l-lg flex items-center hover:bg-purple-600 transition-colors duration-300 cursor-pointer">
                  <FaFileImport className="mr-2" />
                  JSON 파일 선택
                  <input
                    type="file"
                    accept=".json"
                    onChange={handleJsonFileChange}
                    ref={fileInputRef}
                    className="hidden"
                  />
                </label>
                <button
                  type="button"
                  onClick={handleImportJson}
                  disabled={!jsonFile}
                  className="bg-purple-600 text-white px-4 py-2 rounded-r-lg hover:bg-purple-700 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  JSON 데이터 가져오기
                </button>
              </div>
            </div>
            
            {webData.length > 0 && (
              <div className="mt-4">
                <p className="text-green-600 dark:text-green-400">
                  {webData.length}개의 스토리 데이터가 있습니다.
                </p>
              </div>
            )}
          </div>

          {stories.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-500 dark:text-gray-400 text-lg">등록된 스토리가 없습니다.</p>
              <Link
                to="/admin/story/new"
                className="inline-block mt-4 px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-300"
              >
                새 스토리 작성하기
              </Link>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      제목
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      카테고리
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      작성일
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      관리
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                  {stories.map((story) => (
                    <tr key={story.id} className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-150">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 dark:text-white">{story.title}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-primary/10 text-primary">
                          {story.category === 'work' ? '작업기' : 
                           story.category === 'interview' ? '인터뷰' : 
                           story.category === 'tips' ? '팁과 정보' : story.category}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {new Date(story.createdAt || Date.now()).toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric' })}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex justify-end space-x-2">
                          <Link
                            to={`/admin/story/new?edit=${story.id}`}
                            className="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300"
                          >
                            <FaEdit size={18} />
                          </Link>
                          
                          <button
                            onClick={() => confirmDelete(story.id)}
                            className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                          >
                            <FaTrash size={18} />
                          </button>
                          
                          {deleteConfirm === story.id && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                              <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm mx-auto">
                                <h3 className="text-lg font-bold mb-4 text-gray-900 dark:text-white">스토리 삭제 확인</h3>
                                <p className="text-gray-700 dark:text-gray-300 mb-6">정말로 이 스토리를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.</p>
                                
                                <div className="flex justify-end space-x-2">
                                  <button
                                    onClick={cancelDelete}
                                    className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-300"
                                  >
                                    취소
                                  </button>
                                  <button
                                    onClick={() => handleDelete(story.id)}
                                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-300"
                                  >
                                    삭제
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default Dashboard;
