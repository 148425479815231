import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaArrowLeft, FaSave, FaUpload, FaTrash } from 'react-icons/fa';
import { getStoryById, addStory, updateStory, handleImageUpload, isAdminLoggedIn } from '../../utils/localDataUtils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// 스토리 작성/수정 폼을 위한 카테고리 선택 옵션
const CATEGORIES = [
  { value: 'work', label: '작업기' },
  { value: 'interview', label: '인터뷰' },
  { value: 'tips', label: '팁과 정보' }
];

// 에디터 설정
const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['blockquote', 'code-block'],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    ['link', 'image'],
    ['clean']
  ]
};

const StoryForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('edit');
  const storyId = id || editId;
  const isEditMode = !!storyId;
  
  // 스토리 상태
  const [formData, setFormData] = useState({
    title: '',
    summary: '',
    content: '',
    category: 'work',
    imageUrl: '',
    author: '관리자'
  });
  
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // 관리자 인증 확인
  useEffect(() => {
    if (!isAdminLoggedIn()) {
      navigate('/admin/login');
    }
  }, [navigate]);

  // 스토리 데이터 불러오기 (편집 모드)
  useEffect(() => {
    if (isEditMode) {
      setLoading(true);
      
      const fetchStory = async () => {
        try {
          const story = await getStoryById(storyId);
          if (story) {
            setFormData({
              title: story.title || '',
              summary: story.summary || '',
              content: story.content || '',
              category: story.category || 'work',
              imageUrl: story.thumbnail || '',
              author: story.author || '관리자'
            });
            
            if (story.thumbnail) {
              setImagePreview(story.thumbnail);
            }
          }
        } catch (error) {
          console.error('스토리 데이터 가져오기 오류:', error);
          setError('스토리 데이터 가져오기 오류가 발생했습니다. 다시 시도해주세요.');
        } finally {
          setLoading(false);
        }
      };
      
      fetchStory();
    }
  }, [isEditMode, storyId]);

  // 입력 필드 변경 처리
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // 에디터 내용 변경 처리
  const handleEditorChange = (content) => {
    setFormData(prev => ({
      ...prev,
      content
    }));
  };

  // 이미지 파일 선택 처리
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // 폼 제출 처리
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.title.trim()) {
      setError('제목을 입력해주세요.');
      return;
    }
    
    if (!formData.content.trim()) {
      setError('내용을 입력해주세요.');
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      // 스토리 데이터 구성
      const storyData = {
        ...formData,
        createdAt: new Date().toISOString()
      };
      
      // 이미지 처리
      if (imageFile) {
        const imageUrl = await handleImageUpload(imageFile);
        storyData.thumbnail = imageUrl;
      }
      
      // 이미지 URL 추출
      if (!storyData.thumbnail && !imageFile && formData.imageUrl === '') {
        const imgRegex = /<img[^>]+src="([^"]+)"/i;
        const match = formData.content.match(imgRegex);
        
        if (match && match[1]) {
          storyData.thumbnail = match[1];
        }
      } else if (formData.imageUrl) {
        storyData.thumbnail = formData.imageUrl;
      }
      
      // 저장 처리
      let savedStory;
      if (isEditMode) {
        savedStory = await updateStory(storyId, storyData);
      } else {
        savedStory = await addStory(storyData);
      }
      
      console.log('저장된 스토리:', savedStory);
      setSuccess(true);
      
      // JSON 데이터 내보내기
      const exportMessage = `
        스토리가 저장되었습니다!
        
        다음 단계: GitHub Pages에 배포하기:
        1. 'JSON 데이터 내보내기' 버튼을 클릭하여 stories.json 파일을 다운로드합니다.
        2. stories.json 파일을 public/data/stories.json 폴더에 업로드합니다.
        3. 'npm run deploy' 명령어를 실행하여 GitHub Pages에 배포합니다.
      `;
      
      alert(exportMessage);
      
      // 성공 후 대시보드로 이동
      setTimeout(() => {
        navigate('/admin/dashboard');
      }, 1500);
    } catch (error) {
      console.error('스토리 저장 오류:', error);
      setError('스토리 저장 오류가 발생했습니다. 다시 시도해주세요.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex justify-center items-center">
        <p className="text-gray-600 dark:text-gray-300">로딩 중...</p>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gray-100 dark:bg-gray-900"
    >
      <div className="bg-white dark:bg-gray-800 shadow">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            {isEditMode ? '스토리 편집' : '새 스토리 작성'}
          </h1>
          <button
            onClick={() => navigate('/admin/dashboard')}
            className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 px-4 py-2 rounded-lg flex items-center hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-300"
          >
            <FaArrowLeft className="mr-2" />
            대시보드로 돌아가기
          </button>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          {error && (
            <div className="mb-6 p-4 bg-red-100 text-red-700 rounded-lg">{error}</div>
          )}

          {success && (
            <div className="mb-6 p-4 bg-green-100 text-green-700 rounded-lg">
              {isEditMode ? '스토리가 수정되었습니다.' : '새 스토리가 추가되었습니다.'}
              대시보드로 이동합니다...
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <div>
                <label htmlFor="title" className="block text-gray-700 dark:text-gray-300 mb-2">제목 *</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  required
                />
              </div>

              <div>
                <label htmlFor="category" className="block text-gray-700 dark:text-gray-300 mb-2">카테고리 *</label>
                <select
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                >
                  {CATEGORIES.map(category => (
                    <option key={category.value} value={category.value}>{category.label}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mb-6">
              <label htmlFor="summary" className="block text-gray-700 dark:text-gray-300 mb-2">요약 *</label>
              <textarea
                id="summary"
                name="summary"
                value={formData.summary}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                rows="3"
                required
              ></textarea>
            </div>

            <div className="mb-6">
              <label htmlFor="content" className="block text-gray-700 dark:text-gray-300 mb-2">내용 *</label>
              <ReactQuill
                theme="snow"
                value={formData.content}
                onChange={handleEditorChange}
                modules={quillModules}
                className="bg-white dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg"
              />
            </div>

            <div className="mb-6">
              <label htmlFor="imageUrl" className="block text-gray-700 dark:text-gray-300 mb-2">이미지 URL</label>
              <div className="flex">
                <input
                  type="text"
                  id="imageUrl"
                  name="imageUrl"
                  value={formData.imageUrl}
                  onChange={handleInputChange}
                  placeholder="https://example.com/image.jpg"
                  className="flex-grow px-4 py-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
                <label className="bg-primary text-white px-4 py-2 rounded-r-lg flex items-center hover:bg-primary-dark transition-colors duration-300 cursor-pointer">
                  <FaUpload className="mr-2" />
                  업로드
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                </label>
              </div>
              {imagePreview && (
                <div className="mt-2 relative w-full h-40">
                  <img src={imagePreview} alt="Preview" className="w-full h-full object-cover rounded-lg" />
                </div>
              )}
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className="bg-primary text-white px-6 py-2 rounded-lg flex items-center hover:bg-primary-dark transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <FaSave className="mr-2" />
                {loading ? '저장 중...' : '저장하기'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default StoryForm;
